/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-28 10:35:22
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-28 10:43:27
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerProOrderGoods = params => axios({
    url: '/api/dealer/customer/dealerProOrderGoods/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerProOrderGoods = params => axios({
    url: '/api/dealer/customer/dealerProOrderGoods/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerProOrderGoods = params => axios({
    url: '/api/dealer/customer/dealerProOrderGoods/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerProOrderGoods = params => axios({
    url:'/api/dealer/customer/dealerProOrderGoods/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerProOrderGoods = params => axios({
    url: '/api/dealer/customer/dealerProOrderGoods/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
